module.exports = [{
      plugin: require('/var/www/superlumen.io/superlumen.io/gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-68021613-1","anonymize":true},
    },{
      plugin: require('/var/www/superlumen.io/superlumen.io/gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/www/superlumen.io/superlumen.io/gatsby/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/var/www/superlumen.io/superlumen.io/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
